import bsCustomFileInput from 'bs-custom-file-input'

//Main styles
require('../scss/app.scss');

window.$ = window.jquery = require('jquery');
require('popper.js');
require('bootstrap');

$(document).ready(function () {
    bsCustomFileInput.init()
})